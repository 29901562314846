@import "adjustments";
/*
*	here is the right place to for WYSIWYG-Styles / CMS-Input
*/

@if ($SidebarAvailable) {
	#sidebar
	{
		margin-top: $default-margin;
		@include breakpoint(large up)
		{
			@include grid-column(3);
		}
	}	
}

@mixin widget_style()
{
	@if ($SidebarWigetTitleBorder) {
		h3
		{
			border-bottom: $SidebarWigetTitleBorder;
		}	
	}
	
	ul
	{
		list-style: none;
		margin-left: 0;
		li
		{
			background: $secondary-color;
			margin-bottom: 1px;
			padding: 2.5%;	
			font-size: 80%;
			&:hover
			{
				background: $primary-color;
				color: white;
				a
				{
					color: white;
				}
			}
		}
	}
	
}
.widget_recent_entries, .widget_categories, .widget_recent_comments, .widget_archive, .widget_meta, .widget_text, .widget_tag_cloud
{
	@include widget_style;
}
.widget_search
{
	//@extend .search-form;
}
img.aligncenter
{
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.table-container
{
	overflow-x: scroll;
}
.alignleft
{
	float: left;
	margin: 0 2em 2em 0;
}
figure.aligncenter
{
	margin: 0 auto;
}
.alignright
{
	float: right;
	margin: 0 0 2em 2em;
}
.wp-caption-text
{
	font-style: italic;
	font-size: 90%;
}
img
{
	max-width: 100%;
}
.wp-caption
{
	max-width: 100%;
}
.wp-caption-text
{
	@extend .text-center;
}
/*
	WP-Gallery-style
*/
.gallery
{
	@include grid-row;
	margin: 0 auto;
	text-align: center;
	overflow: hidden;
	.gallery-item
	{
		//margin: $default-margin;
		min-width: 158px;
		display: flex;           /* establish flex container */
		flex-direction: column;  /* make main axis vertical */
		justify-content: center; /* center items vertically, in this case */
		align-items: center;     /* center items horizontally, in this case */
		.attachment-thumbnail
		{
			//padding-top: 1rem;
		}
		.gallery-icon
		{
			padding-top: $galleryImageTopBottomSpacing;
			padding-bottom: $galleryImageTopBottomSpacing;
		}
	}
/*
	.gallery-icon
	{
		a
		{
			@include thumbnail;
		}
	}
*/
}
.gallery-caption
{
	@include card-section;
	max-width: 100%;
}
.gallery-item
{
	@include card-container();
}
/*
	max 4 columns at 900px container
*/
.gallery-columns-1
{
	.gallery-item
	{
		@include breakpoint(medium up) {
			@include grid-column(12);	
		}
	}
}
.gallery-columns-2
{
	.gallery-item
	{
		@include breakpoint(medium up) {
			@include grid-column(6);
		}
	}
}
.gallery-columns-3
{
	.gallery-item
	{
		@include breakpoint(medium up) {
			@include grid-column(4);
		}
	}
}
.gallery-columns-4
{
	.gallery-item
	{
		@include breakpoint(medium up) {
			@include grid-column(3);
		}
	}
}

#cookieChoiceDismiss
{
	@include button($button-sizes(small));
	@include breakpoint(medium up)
	{
		margin: 0 0 0 1rem !important;
	}
}
#cookieChoiceInfo
{
	padding: 1rem !important;
	font-size: 0.75rem;
	span
	{
		margin-bottom: 0.5rem;
		@include breakpoint(large up)
		{
			font-size: 1.0rem;
		}
	}
	#cookieChoiceDismiss
	{
		margin-top: 1rem !important;
		max-width: 320px;
		margin-right: auto !important;
		margin-left: auto !important;
		display: block;
	}
}