/*!
Theme Name: Contactlinseninstitut Bronk
Theme URI: http://bronk.eu
Author: JG-Bits UG (haftungsbeschränkt)
Author URI: https://www.jg-bits.de
Description: Dies ist ein individuelles WordPress-Theme für das Contactlinseninstitut Bronk auf der theme4WP-Basis von JG-Bits
Version: 2.0.1
License: Singledomain
License URI: https://www.jg-bits.de/agb
Text Domain: theme4wp

*/

/*
*	Import settings and components
*/
@import "cms-styles";

//Main Design
html, body
{
	word-wrap: break-word;
	height: 100%;
}
#siteDescription
{
	text-align: center;
	h2
	{
		font-size: 100%;
	}
	p
	{
		letter-spacing: 4px;	
	}
}

@if ($hasFooterWrapper) {
	.wrapper
	{
		//min-height: 100%;
		//@include footer-height-wrapper-mixin();
	}
	footer#mainfooter {
		// .push must be the same height as footer
		//@include footer-height-footer-mixin(); 
	}
	.wrapper:after 
	{
	  content: "";
	  display: block;
	}
	
}

.menu-scroll-down {
	color: #fff;
	top: 0em;
	display: block;
	padding: 1em;
	position: absolute;
	right: 0;
	.icon-arrow-right
	{
		-webkit-transform: rotate(90deg);
		/* Chrome, Safari, Opera */
		-ms-transform: rotate(90deg);
		/* IE 9 */
		transform: rotate(90deg);
		height: 18px;
		width: 18px;
	}
}
.screen-reader-text
{
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	overflow: hidden;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important;
}
.wrap
{
	background: $body-background;
	position: relative;
}

@if ($hasOffcanvasMenu) {
	.title-bar {
		background-color: $primary-color;
		color: $white;
		h1 {
			font-size: 1.25rem;
			margin-bottom: 0;
			color: $white;
		}
		.menu-icon:after {
			background: $body-background !important;
			box-shadow: 0 7px 0 $body-background, 0 14px 0 $body-background !important;
		}
	}	
	
	ul.off-canvas-list {
		list-style-type: none;
		padding: 0;
		margin: 0;
		font-size: 1rem;
		line-height: 1.6;
		margin-bottom: 1.25rem;
		list-style-position: outside;
		font-family: inherit;
		
		li a {
			display: block;
			padding: 0.66667rem;
			color: rgba(0, 0, 0, 0.7);
			border-bottom: 1px solid #bfbfbf;
			-webkit-transition: background 300ms ease;
			transition: background 300ms ease;
			text-decoration: none;
		}
		li a.active {
			background-color: $primary-color !important;
			color: rgba(255, 255, 255, 0.7) !important;		
		}
		li.has-submenu i.fa {
			position: absolute;
			right: 1rem;
			top: 0.5rem;
			font-size: 180%;
			padding: 0 1.5rem;
		}
		li.has-submenu {
			position: relative;
			ul.submenu {
				list-style: none;
			}
		}
	}

	#offCanvasRight {
		padding-top: 2rem;
	}
}

.page, .error404
{
	header#masthead
	{
		position: relative;
		@include breakpoint(large up)
		{
			height: $headerPageImageHeight;
		}
		max-height: 60%;
		.site-title
		{
			margin-bottom: 0;
			text-decoration: none;
			font-weight: 500;
			@include breakpoint(medium up)
			{
				line-height: 1.0;
			}
			a
			{
				text-decoration: none;
				font-weight: 500;
				@include breakpoint(medium up)
				{
					font-weight: normal;
					font-size: 2rem;
				}
			}
		}
		.site-title-only
		{
			color: white;
			margin: 0;
			padding: 0;
			vertical-align: middle;
			display: table-cell;
		}
		.site-title-only.large-site-title
		{
			font-size: 1rem;
			@include breakpoint(medium up)
			{
				font-size: 1.25rem;
			}
			@include breakpoint(large up)
			{
				font-size: 1.5rem;
			}
		}
		.wp-custom-header img
		{
			@include breakpoint(large up)
			{
				position: fixed;
				height: auto;
				left: 50%;
				max-width: 1000%;
				min-height: 100%;
				min-width: 100%;
				min-width: 100vw;
				/* vw prevents 1px gap on left that 100% has */
				width: auto;
				top: 50%;
				padding-bottom: 40vh;
				/* Prevent header from extending beyond the footer */
				-ms-transform: translateX(-50%) translateY(-50%);
				-moz-transform: translateX(-50%) translateY(-50%);
				-webkit-transform: translateX(-50%) translateY(-50%);
				transform: translateX(-50%) translateY(-50%);
			}
		}
		.custom-header
		{
			margin-bottom: 0px;
			height: auto;
			width: 100%;
			.custom-header-secondpart
			{
				@include breakpoint(large up)
				{
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;	
				}
			}
			.custom-header-media
			{
				position: relative;
				@include breakpoint(large up)
				{
					position: inherit;
				}
				
				
			}
			.site-branding-container
			{
				.site-title
				{
					color: $white;
				}
				position: absolute;
				@include breakpoint(medium up)
				{
					display: inline-flex;
				}
				@include breakpoint(large up)
				{
					left: auto;
				}
				bottom: $default-padding/2;
				.site-logo
				{
					flex: 0 0 auto;
					img
					{
						display: block;
					}
				}
				.site-branding-text-wrapper
				{
					height: 100%;
					background: rgba(0, 0, 0, 0.60);
				}
			}
			.site-branding
			{
				h1 a
				{
					color: white;
				}
				img, .site-branding-text
				{
					margin: 0;
					.site-description
					{
						line-height: 1.6;
						margin-bottom: 0;
						@include breakpoint(medium up)
						{
							font-weight: normal;
							font-size: 1rem;
						}
						color: white;
					}
				}
				.site-branding-text
				{
				    display: table;
					p
					{
						margin-bottom: 0 !important;
					}
					  
					padding: 0.75rem;
					@include breakpoint(medium up)
					{
						padding: 2rem;
					}
	
				}
			}
		}
	}
}

/*
.theme4wp-front-page
{
	header#masthead
	{
		@include breakpoint(large up)
		{
			height: $headerFrontpageImageHeight !important;
		}
		.wp-custom-header img
		{
			padding-bottom: 1px;
		}
	}
}
*/

//Main style
#primary
{
	position: relative;
	background: $body-background;
	@if ($contentImagesTopBottomSpacing)
	{
		img
		{
			@include breakpoint(small only)
			{
				margin-top: $contentImagesTopBottomSpacing;
				margin-bottom: $contentImagesTopBottomSpacing;	
			}
		}	
	}
}

@if ($hasDefaultMenu) {
	#main-nav
	{
		background: $topbar-background;
		border-bottom: 1px solid #eee;
		border-top: 1px solid #eee;
		a
		{
			color: $body-font-color;
		}
		width: 100% !important;
		z-index: 1000;
		.site-title
		{
			padding-left: .5rem;
			font-size: 120%;
			margin-bottom: 0;
		}
		.title-bar
		{
			display: flex;
			align-items: center;
			.menu-icon
			{
				margin-left: auto;
			}
		}
	}
}

.blog, .archive, .search
{
	.page-header
	{
		padding-top: $default-padding;
	}
	header#masthead
	{
		.custom-header
		{
			position: relative;
		}
		.site-branding
		{
			padding-top: 1rem;
			padding-bottom: 1rem;
			@include breakpoint(large up)
			{
				position: absolute;
				top: 50%;
				left: 50%;
				/*transform: translateX(-50%) translateY(-50%);*/
				transform: translate(-50%, -50%);
			}
		}
		.custom-header-media
		{
			@include breakpoint(large up)
			{
				height: $headerBlogImageHeight;	
			}
			img
			{
				width: 100%;
			}
			#wp-custom-header
			{
				width: 100%;
				@include breakpoint(large up)
				{
					position: fixed;	
				}
			}
		}
		.custom-header-secondpart
		{
			@include breakpoint(large up)
			{
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
			}
			
		}
	}
	.entry-title
	{
		@include header-size(h3);
	}
	.entry-title, .entry-header, .entry-content, .entry-footer
	{
		padding: $default-padding/2;
		margin: 0;
	}
	#main
	{
		article
		{
			margin: $default-margin 0;
			box-shadow: 0px 10px 30px 9px rgba(211, 211, 211, 0.75);
		}
	}
	.entry-header
	{
		.fa
		{
			margin-right: .5rem;
		}
		.categories, .tags
		{
			margin-bottom: 0.75rem;
			margin-top: 0.75rem;
			a
			{
				background: $secondary-color;
				color: white;
				text-decoration: none;
				border-radius: 50px;
				font-size: 1rem;
			}
		}
	}
	.entry-footer
	{
		background-color: $postlistEntryFooterBackgroundColor;
		.fa
		{
			margin-right: .5rem;
		}
		.info-block
		{
			margin-right: 1.5rem;
		}
	}
}
.single
{
	header#masthead
	{
		.custom-header
		{
			position: relative;
		}
		.site-branding
		{
			padding-top: 1rem;
			padding-bottom: 1rem;
			@include breakpoint(large up)
			{
				position: absolute;
				top: 50%;
				left: 50%;
				/*transform: translateX(-50%) translateY(-50%);*/
				transform: translate(-50%, -50%);
			    width: 100%;
			}
			.entry-meta
			{
				span
				{
					//width: 100%;
					margin-bottom: .75rem;
					@include header-size(h6);
					a
					{
						color: white;
						
					}
					.text, time
					{
						font-family: $body-font-family;
					}
				}
			}
		}
		.custom-header-media
		{
			@include breakpoint(large up)
			{
				height: $headerSingleImageHeight;
				min-height: $headerSingleImageMinHeight;	
			}
			img
			{
				width: 100%;
			}
			#wp-custom-header
			{
				@include breakpoint(large up)
				{
					position: fixed;	
				}
			}
		}
		.custom-header-secondpart
		{
			@include breakpoint(large up)
			{
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
			}
			
		}
	}
	.fa
	{
		margin-right: .5rem;
	}
	.categories, .tags
	{
		margin-bottom: 0.75rem;
		a
		{
			background: $secondary-color;
			color: white;
			text-decoration: none;
			border-radius: 50px;
			font-size: 1rem;
		}
	}
	.entry-author
	{
		background-color: $postSingleEntryAuthorBackgroundColor;
		padding: $postSingleEntryAuthorPadding;
		margin-bottom: 1rem;
		@if ($postSingleEntryAuthorShadow){
			box-shadow: $postSingleEntryAuthorShadow;		
		}
		img {
			@include center-block;
			@include breakpoint(medium up) {
				float: left;
				margin-right: 2rem;
				margin-bottom: 2rem;
			}
		}
	}
}
.single, .blog, .archive, .search
{
	h1, h2, h3, h4, h5, h6
	{
		a
		{
			text-decoration: none;
		}
	}
	@if ($headerHasImage) {
		.wrapper
		{
			background-color: $overlayBackgroundColor;
		}
	}
	.custom-header-secondpart
	{
		background-color: $overlayBackgroundColor;
		height: 100%;
		.site-branding
		{
			.site-branding-container
			{
				color: white;
				text-align: center;
				h1
				{
					color: white;
				}
				width: 100%;
			}
		}
	}
}

.page, .single, .error404
{
	#main
	{
		padding-top: $default-padding;
		padding-bottom: $default-padding;
		@if ($imageShadow)
		{
			img
			{
				
				box-shadow: $imageShadow;
			}	
		}
	}
	
}

.page, .error404
{
	article
	{
		background: $postArticleBackgroundColor;
	}
}

//single-Post
.tags, .categories 
{
	margin-bottom: 0.75rem;
	a
	{
		@extend .label;
		&:hover
		{
			background-color: $primary-color;
		}
		background: grey;
		color: white;
		text-decoration: none;
		border-radius: 50px;
		font-size: 1rem;
	}
}
//comments
#comments
{
	clear: both;
	h3
	{
		span
		{
			border-bottom: 1px solid #000;
		}
	}
}
.singleComment
{
	list-style: none;
	padding-top: 15px;
	padding-bottom: 25px;
}
.comment-author-info
{
	ul
	{
		list-style: none;
	}
	img
	{
		float: left;
		margin-right: 1.25rem;
		background: #F9F7F5;
	}
	a
	{
		text-decoration: underline;
	}
}
.comment-author
{
	@extend h4;
}
.comment-text
{
	clear: both;
}
.reply
{
	a
	{
		text-decoration: underline;
		color: lighten($primary-color, 15%);
	}
}
#respond
{
	input#submit
	{
		@extend .button;
		@extend .small;
	}
}

/*
	Sidebar
*/
#mainsidebar {
	padding-top: 2rem;
}
//Footer
footer#mainfooter
{
	padding-top: $default-padding;
	@if ($footer-background-color) {
		background: $footer-background-color;
	}
	color: white;
	h1,h2,h3,h4,h5,h6
	{
		color: white;
	}
	a
	{
		color: white;
		text-decoration: underline;
	}
	.contact-information
	{
		font-size: 1.5rem;
		i
		{
			padding-right: 1rem;
		}
	}
	position: relative;
	clear: both;
	text-align: center;
	padding-bottom: 1.25rem !important;
	.copyright
	{
		margin-top: 2rem;
	}
}